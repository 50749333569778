export type User = {
  id: number;
  uuid: string;
  username: string;
  email: string;
  credit_balance: number;
  is_premium: boolean;
  was_premium: boolean;
  created_at?: string;
  pubsub_token: string;
  active_generations?: number[];
  custom_attributes: {
    gender: string;
    orientation: string;
    accept_tos_18: boolean;
    picture: string;
    default_language: string | null;
    user_language: string | null;
    name: string;
    turn_ons: string[] | null;
    app_mode: string | 'crush:nsfw';
    description: string;
    refer_source?: string;
    feedback_submitted?: boolean;
    feedback_prompt_num?: number;
    offer_type?: string;
    credits_offer?: {
      expires: string;
      amount: number;
    } | null;
    discount_offer?: {
      expires: string;
      amount: number;
    } | null;
  };
};

export type UpdateUserBody = {
  username?: string;
  custom_attributes: {
    name: string;
    gender: string;
    orientation: string;
    accept_tos_18: boolean;
    picture: string;
    refer_source?: string | null;
    refer_id?: string | null;
    refer_domain?: string | null;
    refer_domain_initial?: string | null;
    refer_path?: string | null;
    default_language?: string | null;
    user_language?: string | null;
    app_mode?: string | null;
    description?: string | null;
  };
};

export type PostFeedbackPayload = {
  type: string;
  message: string;
  current_url: string;
};

export type BecomeAffiliatePayload = {
  email: string;
  first_name: string;
  last_name: string;
  social_url: string;
  telegram_id: string;
  website: string;
  join_reason: string;
  promote_explanation: string;
  tell_us_more: string;
  paypal_email: null;
  company_name: null;
  vat_id: null;
  email_optin: false;
};

export type RewardTask = {
  id: number;
  name: string;
  title: string | null;
  description: string | null;
  active: boolean;
  visible: boolean;
  schedule: string;
  completion: string;
  priority: number;
  external_url: string | null;
  input_structure: any; // jsonb type
  credit: number;
  max_claims: number | null;
  claims_count: number;
  created_at: string;
  updated_at: string;
  expires_at: string | null;
  last_completed_at: string | null;
};

export type ClaimRewardTaskPayload = {
  id: number;
  input?: string;
}

export const isUserNsfw = (user: User): boolean => {
  return user.custom_attributes.app_mode === 'crush:nsfw';
};